.CategoryTree-container {
    padding: 0 0 1rem 1rem!important;
    position: relative;
    border: 1px solid #f0f0f0;
    border-right: 0;
    padding-bottom: 0!important;
    border-radius: 3px;
    border-collapse: collapse;
    margin-top: 0.5rem;
}

.CategoryTree-isExpanded .CategoryTree-container {
    width: inherit;
}

.CategoryTree-details {
    display: flex;
    align-items: flex-start;
	padding: 0.5rem;
	border: 1px solid transparent;
    margin-left:-1rem;
}

.CategoryTree-buttons {
    margin: -0.5rem 0 0 -1rem;
    background-color: #f3f3f3;
}


.CategoryTree-selected {
	background: rgb(163, 218, 240);
	border:1px solid rgb(70, 138, 228);
	border-radius: 3px;
}

.CategoryTree-name {
    word-break: break-word;
    padding: 5px 0;
}

.CategoryTree-status-icon {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.CategoryTree-status-todo {
    color: red;
}

.CategoryTree-status-inprogress {
    color: blue;
}

.CategoryTree-status-done {
    color: green;
}
