.Catalogue-container {
    display: flex;
    flex-direction: row;
}

.Catalogue-box {
    background-color: #fff;
    border: 1px solid #c4c5c5;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    border-radius: 3px;
    margin: 0.5rem;
    padding: 1rem;
    flex-grow: 2;
}

.Catalogue-entities {
    display: flex;
    flex-direction: column;
    flex: 1;
}
